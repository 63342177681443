<template>
  <v-app-bar
    app
    elevate-on-scroll
  >
    <v-toolbar-items>
      <v-row
        class="max-height"
        dense
        justify="center"
        align="center"
      >
        <img
          class="mr-3"
          :src="require('@/assets/logoappbar.png')"
          height="45"
          align-center
          center
          style="display: block; margin:0 auto; padding-left: 22px"
        >
      </v-row>
    </v-toolbar-items>
    <v-spacer />
    <v-toolbar-items class="hidden-md-and-up">
      <v-app-bar-nav-icon @click="drawer = !drawer" />
    </v-toolbar-items>
    <v-toolbar-title />
    <v-btn
      :key="icon"
      class="mx-4 black--text"
      icon
      @click="scrollTotop"
    >
      <v-icon size="24px">
        {{ icon_home }}
      </v-icon>
    </v-btn>
    <v-toolbar-items class="hidden-sm-and-down">
      <v-menu
        v-model="value_services"
        :disabled="disabled_services"
        :absolute="absolute_services"
        :open-on-hover="openOnHover_services"
        :close-on-click="closeOnClick_services"
        :close-on-content-click="closeOnContentClick_services"
        :offset-x="offsetX_services"
        :offset-y="offsetY_services"
      >
        <template
          v-slot:activator="{ on }"
        >
          <v-btn
            text
            :elevation="0"
            v-on="on"
            v-html="$t('message.ourservices')"
          />
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items_services_sub"
            :key="index"
          >
            <v-btn
              block
              small
              rounded="false"
              @click="scrollToservices(item.div)"
              v-html="m_items_services(index)"
            />
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        text
        @click="scrollTomarket"
      >
        {{ this.$t('message.market') }}
      </v-btn>
      <v-btn
        text
        @click="scrollTonews"
      >
        {{ this.$t('message.news') }}
      </v-btn>
      <v-btn
        text
        @click="scrollToabout"
      >
        {{ this.$t('message.aboutus') }}
      </v-btn>
      <v-btn
        text
        @click="scrollTocontact"
      >
        {{ this.$t('message.contact') }}
      </v-btn>
    </v-toolbar-items>
    <v-toolbar-items>
      <v-menu
        v-model="value"
        :disabled="disabled"
        :absolute="absolute"
        :open-on-hover="openOnHover"
        :close-on-click="closeOnClick"
        :close-on-content-click="closeOnContentClick"
        :offset-x="offsetX"
        :offset-y="offsetY"
      >
        <template
          v-slot:activator="{ on }"
        >
          <v-btn
            class="white"
            :elevation="0"
            v-on="on"
          >
            <flag :iso="$t('message.flag')" />
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
          >
            <v-btn @click="changeLocale(item.locale)">
              <flag :iso="item.flag" />
              <v-btn text>
                {{ item.title }}
              </v-btn>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
  // Utilities
  import {
    sync,
  } from 'vuex-pathify'

  export default {
    name: 'FrontendAppBar',
    data: () => ({
      menu: [
        { icon: 'home', title: 'Link A' },
        { icon: 'info', title: 'Link B' },
        { icon: 'warning', title: 'Link C' },
      ],
      items: [
        { title: ' English', flag: 'us', locale: 'en' },
        { title: ' Français', flag: 'fr', locale: 'fr' },
      ],
      items_services_sub: [
        { div: 'services1' },
        { div: 'services2' },
        { div: 'services3' },
      ],
      icon_home: '$mdiHome',
      disabled: false,
      absolute: false,
      openOnHover: true,
      value: false,
      closeOnClick: true,
      closeOnContentClick: true,
      offsetX: false,
      offsetY: true,
      disabled_services: false,
      absolute_services: false,
      openOnHover_services: true,
      value_services: false,
      closeOnClick_services: true,
      closeOnContentClick_services: true,
      offsetX_services: false,
      offsetY_services: true,
    }),

    computed: {
      ...sync('frontend/*'),
    },
    methods: {
      changeLocale: function (locale) {
        this.$i18n.locale = locale
      },
      m_items_services: function (index) {
        return this.$t('message.ourservices_sub')[index]
      },
      top () {
        window.scrollTo(0, 0, 'smooth')
      },

      scrollTotop: function () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('topdiv')
        this.$scrollTo(element, '500', options)
      },
      scrollToservices: function (adiv) {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById(adiv)
        this.$scrollTo(element, '500', options)
      },

      scrollTomarket () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('market')
        this.$scrollTo(element, '500', options)
      },
      scrollTonews () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('news')
        this.$scrollTo(element, '500', options)
      },
      scrollToabout () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('about')
        this.$scrollTo(element, '500', options)
      },
      scrollTocontact () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('contact')
        this.$scrollTo(element, '500', options)
      },
    },
  }
</script>
